<template>
  <!-- 每日清单查询 -->
  <div class="daily-list">
    <!-- 切换就诊人 -->
    <SwitchPeople />
    <div class="daily-region">
      <!-- 日历 -->
      <div class="calendar">
        <van-cell
          title="选择日期"
          :value="date"
          @click="show = true"
          size="large"
        />
        <van-calendar
          v-model="show"
          @confirm="onConfirm"
          color="#4d7cfe"
          position="bottom"
          :min-date="minDate"
          :max-date="maxDate()"
          :round='false'
        />
      </div>
      <!-- 清单 -->
      <div
        class="detailed-list"
        v-show="date.length != ''"
      >
        <div v-if="dailyList == undefined || dailyList == null || dailyList == '' ? false : true">
          <div class="detailed large">
            <span>当天费用总额</span>
            <span style="color:#e63b39">{{money()}}</span>
          </div>
          <Scroll
            class="region-scroll"
            ref="scroll"
          >
            <div
              class="detailedlist"
              v-for="(item,index) in dailyList"
              :key="index"
            >
              <div class="itemname">{{item.itemname}}</div>
              <div>{{item.price + '元'}}</div>
            </div>
            <!-- <van-collapse
            v-model="activeNames"
            v-for="(item,index) in dailyList"
            :key="index"
          >
            <van-collapse-item
              :title="item.itemname"
              :value="item.amt + '元'"
              :name="index"
              size="large"
            >
              <van-cell-group>
                <van-cell
                  :title="item.itemclass"
                  :value="item.itemno + item.unit+ '                    ' + item.price + '元'"
                />
              </van-cell-group>
            </van-collapse-item>
          </van-collapse> -->
          </Scroll>
        </div>
        <van-empty
          v-else
          description="暂无住院记录"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Scroll from '@/components/content/scroll/BScroll'
import SwitchPeople from '@/components/common/switchPeople/switchPeople'
import { times, standard } from '@/common/utils'
import { jd2hisInpatientDaylist, inpatientIdcard } from '@/network/service'
import { mapState } from 'vuex'
export default {
  name: 'dailyList',
  components: {
    SwitchPeople,
    Scroll
  },
  data () {
    return {
      //手风琴当前激活
      activeNames: ['0'],
      //选择时间显示
      date: '',
      //日期蒙版开关
      show: false,
      //历史日期
      minDate: new Date(2000, 0, 1),
      //每日清单
      dailyList: [],
    };
  },
  computed: {
    ...mapState(['cardList', 'seq'])
  },
  mounted () {
    this.date = standard(this.maxDate())
    this.jd2hisInpatientDaylistPost(standard(this.maxDate()))
  },
  methods: {
    //总额
    money () {
      let totalprice = this.dailyList.reduce((total, item) => {
        return total += Number(item.price)
      }, 0)
      return totalprice.toFixed(2) + '元'
    },
    //当前日期
    maxDate () {
      let date = (times(true).slice(0, 10)).split('-')
      return new Date(date[0], date[1] - 1, date[2] - 1)
    },
    //日期格式化
    formatDate (date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    },
    //蒙版
    onConfirm (date) {
      this.show = false;
      this.date = this.formatDate(date);
      //每日清单请求
      this.jd2hisInpatientDaylistPost(standard(date))
    },
    async jd2hisInpatientDaylistPost (date) {
      //住院信息请求
      let res = await inpatientIdcard({
        med_id: this.cardList[0].cardNo,
        card_type: this.cardList[0].cardType,
      });
      if (res.code == "0") {
        //每日清单请求
        let time = await jd2hisInpatientDaylist({
          admission_no: res.data.root.body.admission_no,
          check_code_fixed: res.data.root.body.in_pid,
          start_date: date,
          end_date: this.getnextday(date)
        })
        if (time.code == "0") {
          console.log(time)
          this.dailyList = time.data.root.body.detail
          this.$nextTick(() => {
            this.$refs.scroll.refresh()
          })
        } else {
          this.$toast(time.msg);
        }
      } else {
        this.$toast(res.msg);
      }
    },
    getnextday (date) {
      date = new Date(date)
      date = +date + 1000 * 60 * 60 * 24
      date = new Date(date)
      return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    }
  },
}
</script>

<style lang="less" scoped>
/deep/.van-collapse-item {
  border-top: 1px solid @lineColor;
}
.daily-list {
  height: 100vh;
  .daily-region {
    .calendar {
      border-bottom: 1px solid @lineColor;
    }
    .detailed-list {
      margin: 0.5rem auto;
      /deep/.van-hairline--top-bottom::after,
      .van-hairline-unset--top-bottom::after {
        border-width: 0;
      }
      /deep/.van-cell__value {
        color: #323233;
        white-space: pre;
      }
      .detailed {
        display: flex;
        justify-content: space-between;
        line-height: 3rem;
        padding-left: 1rem;
        padding-right: 2rem;
        font-weight: 600;
        border-bottom: 1px solid #f5f5f5f5;
      }
      .region-scroll {
        height: calc(100vh - 9.5rem);
        overflow: hidden;
        .detailedlist {
          height: 2rem;
          line-height: 2rem;
          border-bottom: 1px solid #f5f5f5f5;
          padding: 0 0.5rem;
          display: flex;
          justify-content: space-between;
          .itemname {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            flex: 0.8;
          }
        }
      }
    }
  }
}
</style>